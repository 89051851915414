.Favorite {
    width: 40%;
    max-height: 350px;
    display: inline-flex;
    overflow: hidden;
    margin: 15px 30px;
}

.info {
    text-align: center;
    padding: 2%;
    width: 60%;
    color: white;
    border: 2px white dotted;
    border-left: none;
    border-radius: 0px 15px 15px 0px;
    backdrop-filter: blur(5px) brightness(0.7);
}

.img-container {
    width: 40%;
    overflow: hidden;
}

.Favorite img {
    margin-left: 50%;
    transform: translateX(-50%);
    height: 100%;
}

p, ul {
    font-size: 16px;
}

h2 {
    font-size: 30px;
    margin: 0;
    margin-top: 5px;
}

.Favorite ul, h4 {
    font-weight: normal;
    margin: 0;
    margin-left: 2%;
    text-align: left;
    font-size: 17px;
    color: white !important;
}

@media screen and (max-width: 1300px) {
    .Favorite {
        width: 90%;
    }
}

@media screen and (max-width: 650px) {

    .Favorite {
        width: 80%;
        height: auto;
        display: block;
        max-height: none;
        margin: 0px auto;
        margin-bottom: 30px;
    }

    .Favorite .info {
        width: 100%;
        border: 2px white dotted;
        border-top: none;
        border-radius: 0px 0px 10px 10px;
        margin: 0px;
        padding: 30px;
        box-sizing: border-box;
    }

    .Favorite .img-container {
        width: 100%;
        height: 350px;
        border-radius: 10px 10px 0 0;
    }
}

@media screen and (max-width: 450px) {
    .Favorite .img-container{
        height: 250px;
    }
}