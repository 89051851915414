.ContactInfo {
    width: 40%;
    height: 100%;
    display: block;
}

.ContactInfo .contact-section {
    display: flex;
    margin-top: 20px;
    width: 100%;
}

.ContactInfo .icon {
    height: 40px;
}

.ContactInfo .social-icon {
    height: 30px;
    margin: 20px;
}

.ContactInfo .contact-text {
    padding-top: 7px;
    font-size: 22px;
    width: 100%;
}

.ContactInfo hr {
    border: none;
    height: 2px;
    background-color: white;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 30px;
}

@media screen and (max-width: 980px) {
    .ContactInfo {
        width: 100%;
    }
}