body {
  margin: 0;
  font-family: 'Alegreya Sans SC', sans-serif;
}

.heading {
  font-weight: 700;
}

.subheading {
  font-weight: 300;
}