.section-name {
    font-size: 50px;
    color: rgb(48, 48, 48);
    font-weight: bold;
    margin: 0;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 15px;
}

.SmoothiesContainer {
    padding-left: 5%;
    padding-right: 5%;
    border-radius: 4px;
    margin: 5px;
}

hr {
    margin-left: 50px;
    margin-right: 100px;
    margin-top: 50px;
}

.MenuSection.blurred {
    background-color: gray;
}

@media screen and (max-width: 900px) {
    .section-name {
        text-align: right;
        padding-right: 40px;
    }
}

@media screen and (max-width: 450px) {
    .SmoothiesContainer {
        padding: 0px;
        margin: 0px;
    }

    .section-name {
        font-size: 40px;
    }
}