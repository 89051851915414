.contact-background-image {
    min-height: calc(100vh + 1px);
    background-color: rgb(75, 79, 104);
    text-align: center;
}

.Contact {
    color:rgb(231, 231, 231);
}

.Contact .Navbar {
    margin-bottom: 0;
}

.Contact span {
    font-size: 80px;
}

.Contact p {
    margin: 10px;
    font-size: 25px;
}

.Contact .container hr {
    margin: 20px;
    border: dashed 2px rgb(140, 143, 155);
    background-color:rgb(90, 94, 119);
    height: 0px;
}

.Contact h2 {
    font-size: 40px;
    margin-bottom: 0;
    font-weight: normal;
}

.Contact .container {
    min-height: 791px;
    width: 80%;
    background-color: rgb(90, 94, 119);
    padding-top: 35px;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
}

.Contact .contact-info-container {
    margin-top: 30px;
    display: flex;
    height: calc(100% - 275px);
    margin-left: 10%;
    margin-right: 10%;
}

.Contact form {
    display: block;
    height: 100%;
    width: 60%;
}

.Contact input {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: rgb(26, 26, 26);
    margin-top: 20px;
    padding: 3px;
    padding-left: 15px;
    width: 80%;
    height: 30px;
    border: none;
    border-radius: 6px;
}

.Contact input[type=submit] {
    width: 170px;
}

.Contact input[type=submit]:hover {
    background-color:rgb(224, 224, 224);
    cursor: pointer;
}

.Contact .icon {
    height: 40px;
}

.Contact textarea {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: rgb(26, 26, 26);
    padding-top: 15px;
    padding-left: 15px;
    margin-top: 20px;
    width: 80%;
    height: 250px;
    border: none;
    border-radius: 6px;
    resize: none;
}

.Contact form.emailjs-sending {
    filter: opacity(0.8);
    pointer-events: none;
}

@media screen and (max-width: 980px) {
    .Contact .contact-info-container {
        display: block;
    }

    .Contact form {
        width: 100%;
        margin-bottom: 80px;
    }
}

@media screen and (max-width: 600px) {
    .Contact span {
        font-size: 50px;
    }

    .Contact h2 {
        font-size: 35px;
    }

    .Contact p {
        font-size: 22px;
        padding: 0px 20px;
    }

    .Contact .container {
        margin: 0;
        width: 100%;
    }
}