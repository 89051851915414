.MenuDisplay {
    display: flex;
}

.MenuSectionDisplay {
    width: 70%;
    padding: 10px;
    margin-left: 4%;
    border-radius: 4px;
    display: inline-block;
    vertical-align: top;
}

@media screen and (max-width: 980px) {
    .MenuDisplay {
        display: block;
    }

    .MenuSectionDisplay {
        width: 100%;
        min-width: 0;
        margin-left: 0;
    }
}

@media screen and (max-width: 450px) {
    .MenuSectionDisplay {
        padding: 0;
        margin: 0;
    }
}