.NutritionFacts.displayed{
    display: block;
    position: fixed;
    width: 650px;
    height: 500px;
    max-width: 100vw;
    max-height: 100vh;
    top: 50%;
    left: 50%;
    padding: 40px;
    margin-top: -250px;
    margin-left: -325px;
    animation: expand .4s;
    overflow: hidden;
}

.NutritionFacts {
    box-sizing: border-box;
    box-shadow: 0px 0px 1000px 10px rgb(71, 71, 71);
    border: 4px solid rgb(118, 185, 202);
    border-radius: 10px;
    pointer-events: all;
    background-color: white;
    z-index: 1;
    position: absolute;
    display: none;
    text-align: center;
}

.NutritionFacts .contents {
    margin-bottom: 4%;
    display: none;
}

.NutritionFacts.displayed .contents {
    height: 95%;
    display: flex;
    animation: unhide 0.35s;
}

.NutritionFacts .ingredients {
    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-align: left;
    height: 100%;
    overflow: hidden;
    width: 60%;
}

.NutritionFacts .nutrition {
    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    width: 60%;
    float: right;
}

.NutritionFacts h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 35px;
    color: rgb(118, 185, 202);
}

.NutritionFacts h2 {
    font-size: 28px;
}

.NutritionFacts p {
    margin-top: 10px;
    font-size: 20px;
}

.NutritionFacts hr {
    margin: 0;
    height: 3px;
    border-radius: 5px;
    background-color: rgb(118, 185, 202);
    border: none;
    margin-right: 60px;
}

.NutritionFacts ul {
    list-style: none;
    font-style: italic;
    font-size: 18px;
    font-weight: 600;
    padding-left: 20px;
    padding-top: 10px;
}

.NutritionFacts li {
    text-indent: -1em;
}

.NutritionFacts .ingredients h1, h2, p, ul {
    margin-left: 2px;
}

.NutritionFacts li:before {
    content: "+";
    padding-right: 5px;
}

.NutritionFacts button {
    padding: 3px 8px;
    border-radius: 5px;
    width: 0px;
    border: 2px solid rgb(66, 126, 141);
    background-color: rgb(118, 185, 202);
    font-weight: bold;
    transition: 0.1s;
    display: none;
}

.NutritionFacts.displayed button {
    animation: grow 0.3s;
    display: inline;
    width: 150px;
}

.NutritionFacts button:hover {
    cursor: pointer;
    background-color:rgb(100, 167, 184)
}

.NutritionFacts .contents button {
    display: none;
}

.NutritionFacts.contents .nutrition table {
    margin: 0 auto;
    text-align: left;
    border-collapse: collapse;
}

.NutritionFacts .contents .nutrition td {
    padding: 1px 20px;
    text-align: left;
    font-size: 18px;
    margin: 0;
}

.NutritionFacts .contents .nutrition td:nth-child(odd) {
    font-weight: 500;
}

.NutritionFacts .contents .nutrition td:nth-child(even) {
    color: rgb(63, 63, 63);
}

.NutritionFacts .contents .nutrition tr:nth-child(odd) {
    background-color: rgba(118, 185, 202, 0.4);
}

@media screen and (max-width: 980px) {
    .NutritionFacts.displayed {
        display: inline;
    }
}

@media screen and (max-width: 650px) {
    .NutritionFacts.displayed {
        left: 0;
        margin-left: 0;
        padding: 30px;
    }
}

@media screen and (max-width: 601px) {
    .NutritionFacts.displayed .contents button {
        display: inline;
    }

    .NutritionFacts.displayed {
        animation: none;
    }

    .NutritionFacts.displayed .contents {
        display: block;
        animation: none;
    }

    .NutritionFacts.displayed .contents .ingredients {
        height: 90%;
    }

    .NutritionFacts.displayed .contents .nutrition {
        width: 100%;
        height: 90%;
        display: none;
    }

    .NutritionFacts.displayed .contents .nutrition table {
        margin: 0 auto;
        text-align: left;
        border-collapse: collapse;
    }

    .NutritionFacts.displayed .contents .nutrition td {
        padding: 1px 45px;
        font-size: 20px;
    }

    .NutritionFacts.displayed .contents .nutrition td:nth-child(even) {
        padding: 1px 20px;
    }

    .NutritionFacts.displayed .contents .nutrition tr:nth-child(odd) {
        background-color: rgba(118, 185, 202, 0.4);
    }
    
    .NutritionFacts .ingredients {
        width: 100%;
    }
}

@media screen and (max-width: 420px) {
    .NutritionFacts.displayed .contents .nutrition td {
        padding: 1px 25px;
        font-size: 19px;
    }
}

@media screen and (max-width: 330px) {
    .NutritionFacts.displayed .contents .nutrition td {
        padding: 1px 10px;
        font-size: 19px;
    }
}

@keyframes expand {
    0% {
        width: 1px;
        height: 1px;
        top: 50%;
        left: 50%;
        margin-top: 0;
        margin-left: 0;
        padding: 0;
    }
    100% {
        padding: 40px;
    }
}

@keyframes unhide {
    100% {
        white-space: nowrap;
    }
}

@keyframes grow {
    0% {
        width: 0px;
        height: 0px;
        border: none;
        overflow: hidden;
        padding: 0px;
    }
    100% {
        width: 150px;
    }
}