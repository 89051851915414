.smoothie-container {
    display: inline;
}

.Smoothie {
    font-family: DD-TTNorms, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border: solid 1px rgb(194, 194, 194);
    border-radius: 3px;
    width: 44%;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 150px;
    display: inline-flex;
    transition: 0.2s ease;
}

.Smoothie:hover {
    border-color:rgb(156, 156, 156);
    cursor: pointer;
}

.Smoothie:active {
    background-color: rgb(226, 226, 226);
}

.Smoothie .info {
    backdrop-filter: none;
    width: 90%;
    border: none;
}

.Smoothie .img-container {
    vertical-align: bottom;
    border-radius: 3px 0px 0px 3px;
    height: 150px;
    width: 25%;
    min-width: 70px;
}

.Smoothie img {
    margin-left: 50%;
    transform: translateX(-50%);
    height: 100%;
}

.Smoothie h3, h4 {
    color: rgb(48, 48, 48);
    font-weight: normal;
    padding-left: 2%;
    text-align: left;
}

.Smoothie h3 {
    margin-top: 5px;
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Smoothie h4 {
    padding: 0;
    color:rgb(95, 95, 95) !important;
    font-size: 15px;
}

.truncate-overflow {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.prices {
    font-size: 17px;
    color: rgb(65, 65, 65);
    font-weight: bold;
    text-align: left;
    padding-left: 2%;
    padding-top: 12px;
}

@media screen and (max-width: 1250px) {
    .Smoothie {
        width: 60%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 980px) {
    .Smoothie {
        width: 44%;
    }
}

@media screen and (max-width: 900px) {
    .Smoothie {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 780px) {
    .Smoothie {
        width: 100%;
        margin: 0;
        background-color: rgb(247, 247, 247);
    }
}