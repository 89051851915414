.Menu {
    background-color: rgb(255, 255, 255);
    min-height: calc(100vh + 1px);
    overflow: hidden;
}

.Menu .Navbar {
    margin-bottom: 3%;
}

.Menu.blurred {
    background-color: rgb(202, 202, 202);
    overflow: hidden;
    pointer-events: none;
}

.Menu .left-decor, .right-decor {
    border-top: 110px solid transparent;
    border-bottom: 110px solid transparent;
    position: absolute;
}

.Menu .left-decor {
    border-right: 70px solid transparent;
    border-left: 70px solid rgb(66, 126, 141);
}

.Menu .right-decor {
    border-right: 70px solid rgb(66, 126, 141);
    border-left: 70px solid transparent;
    right: 0px;
}

@media screen and (max-width: 780px) {
    .Menu .left-decor, .right-decor {
        border: none;
    }

    .Menu {
        background-color: rgb(233, 233, 233);
    }
}