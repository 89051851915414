.cont {
    width: 100%;
    background: linear-gradient(
        rgba(177, 177, 177, 0.5),
        rgba(54, 54, 54, 0.5)),
        url("smoothiesbackground.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.Favorites {
    text-align: center;
    display: flexbox;
    justify-content: space-around;
    padding-bottom: 30px;
}

.Favorites h1 {
    white-space: nowrap;
    margin: 0;
    color: white;
    font-size: 90px;
    padding: 20px;
    letter-spacing: 0.5em;
}

@media screen and (max-width: 1200px) {
    .Favorites {
        width: 100%;
    }

    .Favorites h1 {
        letter-spacing: 0.2em;
    }
}

@media screen and (max-width: 800px) {
    .Favorites h1 {
        letter-spacing: 0em;
    }
}

@media screen and (max-width: 400px) {
    .Favorites h1 {
        font-size: 65px;
    }
}

