.about-background-image {
    min-height: calc(100vh + 1px);
    background-color: rgb(75, 79, 104);
    text-align: center;
}

.About {
    color:rgb(231, 231, 231);
}

.About .Navbar {
    margin-bottom: 0;
}

.About span {
    font-size: 80px;
}

.About .container hr {
    margin: 20px;
    border: dashed 2px rgb(140, 143, 155)
}

.About .container {
    min-height: 791px;
    width: 80%;
    background-color: rgb(90, 94, 119);
    padding-top: 35px;
    margin-left: 10%;
    margin-right: 10%;
    display: block;
}

.About .family-contents {
    display: flex;
    padding: 70px;
    padding-left: 120px;
    padding-right: 120px;
}

.About p {
    font-size: 25px;
    padding: 40px;
    padding-top: 0px;
}

.About h2 {
    font-size: 50px;
    font-weight: bold;
    padding-bottom: 30px;
}

.family-contents img {
    border-radius: 3px;
    max-width: 700px;
    height: 100%;
}

@media screen and (max-width: 1600px) {
    .About .family-contents {
        display: block;
    }

    .family-contents img {
        width: 100%;
        height: 100%;
    }

    .About .family-contents {
        padding-top: 40px;
        padding-bottom: 1px;
    }

    .About p {
        padding: 0;
    }
}