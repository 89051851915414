.Order {
    font-size: 30px;
    text-align: center;
}

.Order .temp-contents a {
    display: block;
    width: fit-content;
    margin: 0 auto;
}

.Order .temp-contents img {
    max-height: 200px;
    max-width: 100vw;
}

.Order .Navbar {
    margin-bottom: 90px;
}