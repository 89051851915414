.Navbar {
    text-align: center;
    width: 80%;
    height: 110px;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    background-color: white;
    display: flex;
    opacity: 0.8;
}

.contents {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
}

.button {
    width: 100%;
    font-size: 30px;
    text-decoration: none;
    color: #404040;
}

.order {
    font-size: 32px;
    border-radius: 5px;
    background-color: orange;
    transition: 0.2s;
    max-width: 180px;
    min-width: 180px;
}

.order:hover {
    font-style: initial !important;
    text-decoration: none !important;
    background-color: #f53333;
    color: black;
}

.Navbar a:hover {
    font-style: oblique 10deg;
    text-decoration: underline;
}

.active {
    font-weight: bold;
    text-decoration: underline;
}

.logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.Navbar .hamburger {
    width: 30px;
    height: 28px;
    display: none;
    padding: 10px;
    margin: 20px;
    border-radius: 6px;
    cursor: pointer;
}

.Navbar .hamburger:hover {
    background-color: rgb(226, 226, 226);
}

.Navbar .responsive-contents {
    display: none;
}

@media screen and (max-width: 980px) {
    .logo {
        display: none;
    }

    .navbar {
        padding-left: 0%;
        padding-right: 0%;
        width: 100%;
    }

    .button {
        min-width: 100px;
    }
}

@media screen and (max-width: 600px) {
    .button {
        font-size: 25px;
        min-width: 90px;
    }
}

/* Display hamburger icon in Navbar */
@media screen and (max-width: 450px) {
    .Navbar {
        display: block;
        height: auto;
    }

    .Navbar .contents a {
        display: none;
    }

    .Navbar .contents a:last-child {
        display: inline;
    }

    .Navbar .hamburger {
        display: inline;
    }

    /* Responsive Navbar */
    .Navbar .responsive-contents.on {
        background-color: white;
        display: block;
        padding: 0px 0px 10px 0px;
    }

    .Navbar .responsive-contents.on a {
        display: block;
        margin: 8px 0px;
    }

    .Navbar .responsive-contents.off {
        display: none;
    }

    .Navbar .responsive-contents.on hr {
        margin: 0;
    }
}