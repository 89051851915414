.HowToOrder {
    background-color: #f3f3ef;
    height: 290px;
    padding: 1.5%;
    text-align: center;
    justify-content: center;
}

.HowToOrder h1 {
    margin: 0;
    color: #f53333;
    font-size: 50px;
    font-weight: bold;
    white-space: nowrap;
}

.order-steps {
    padding: 0;
    display: flex;
    justify-content: center;
    height: 200px;
    width: 100%;
}

.step {
    font-size: 18px;
    justify-content: center;
    margin-left: 5%;
    margin-right: 5%;
}

.smoothie-img-style {
    width: 100%;
    max-width: 180px;
    border-radius: 50%;
}

.booster-img-style {
    width: 100%;
    max-width: 240px;
    margin-top: 30px;
}

.arrow {
    margin-top: 100px;
    border: solid red;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 13px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
 
.HowToOrder h2 {
    white-space: nowrap;
}

.HowToOrder .order-now {
    margin-bottom: 50px;
}

.step .button {
    white-space: nowrap;
    font-size: 200%;
    padding: 1% 3%;
}

@media screen and (max-width: 960px) {
    .HowToOrder .step {
        margin: 1%;
    }

    .HowToOrder .arrow {
        border-width: 0 4px 4px 0;
        padding: 10px;
    }
}

@media screen and (max-width: 800px) {
    .HowToOrder {
        height: auto;
    }

    .HowToOrder h1 {
        font-size: 40px;
        margin: 20px 0px;
    }

    .HowToOrder .order-steps {
        display: block;
        height: auto;
        margin-bottom: 20px;
    }

    .HowToOrder h2 {
        font-size: 25px;
    }

    .HowToOrder .step {
        font-size: 15px;
    }

    .HowToOrder .booster-img-style {
        max-width: 220px;
    }

    .HowToOrder .smoothie-img-style {
        max-width: 200px;
    }

    .HowToOrder .order-now {
        margin-bottom: 20px;
    }

    .HowToOrder .arrow {
        display: inline-block;
        transform: rotate(45deg);
        margin: 30px;
        border-width: 0 4px 4px 0;
        padding: 8px;
    }
}

@media screen and (max-width: 380px) {
    .HowToOrder h1 {
        font-size: 32px;
    }

    .HowToOrder .smoothie-img-style {
        max-width: 160px;
    }

    .HowToOrder .booster-img-style {
        max-width: 180px;
    }

    .HowToOrder h2 {
        font-size: 22px;
    }
}