.Footer {
    background-color: rgba(255,255,255,0.6);
    width: 100%;
    height: 50px;
    font-family: Arial;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    box-sizing: border-box;
    padding: 0px 10px;
}

.Footer * {
    width: 50%;
    text-align: center;
    padding: 5px;
}

@media screen and (max-width: 1000px) {
    .Footer {
        height: 45px;
        font-size: 15px;
    }
}

@media screen and (max-width: 800px ) {
    .Footer {
        height: 70px;
        flex-direction: column;
        justify-content: space-around;
    }

    .Footer * {
        width: 100%;
    }
}