.MenuButtons {
    max-height: 500px;
    min-height: 500px;
    min-width: 280px;
    margin-top: 50px;
    margin-left: 7%;
    display: grid;
    text-align: left;
}

.MenuButtons button {
    margin-bottom: 20px;
    font-family: "Alegreya Sans SC";
    font-size: 25px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 13px;
    background-color: rgb(118, 185, 202);
    transition: 0.1s;
    color: white;
}

.MenuButtons button.blurred {
    background-color:rgb(88, 155, 172);
    color: rgb(228, 228, 228);
}

.MenuButtons button:hover {
    cursor: pointer;
    background-color:rgb(136, 214, 233);
}

.MenuButtons button.filter {
    background-color: rgb(66, 126, 141)
}

.MenuButtons button.filter:hover {
    background-color: rgb(86, 146, 161);
}

.MenuButtons .dropdown {
    display: none;
}

@media screen and (max-width: 980px) {
    .MenuButtons {
        min-height: 0;
        width: 70%;
        margin-top: 50px;
        margin-left: 15%;
        display: block;
    }

    .MenuButtons button {
        font-size: 22px;
        margin: 4px;
    }
}

@media screen and (max-width: 900px) {
    .MenuButtons .standard-display {
        display: none;
    }

    .MenuButtons .dropdown {
        display: inline-block;
    }

    .MenuButtons .dropdown-content {
        display: none;
        position: absolute;
        min-width: 200px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.8);
        background-color: white;
        z-index: 1;
    }

    .MenuButtons button {
        display: none;
    }

    .MenuButtons .drop-button img {
        cursor: pointer;
        height: 35px;
        width: 40px;
        padding: 8px;
    }

    .dropdown:hover .dropdown-content {
        display: block;
        border-radius: 5px;
    }

    .MenuButtons .dropdown-content button {
        display: block;
        padding: 12px 16px;
        text-decoration: none;
        color: black;
        background-color: white;
        width: 100%;
        padding: 15px;
        border-radius: 3px;
        margin: 0;
        text-align: left;
    }

    .MenuButtons .dropdown-content hr {
        margin: 0px 20px 0px 10px;
    }

    .MenuButtons .dropdown-content button.filter {
        background-color: rgb(241, 241, 241);
    }
}

@media screen and (max-width: 450px) {
    .MenuButtons {
        margin-top: 30px;
        margin-left: 30px;
    }
}